import { orders } from './orders';
import { employees } from './employees';
import { corporates } from './corporate';
import { Models } from '@rematch/core'
import { wallet } from './wallet'
import { users } from './user';
import { errors } from './errors';
import { services } from './services';
import {events} from './events'
import {report} from './report'
export interface RootModel extends Models<RootModel> {

}

export const  models: RootModel = {wallet, corporates, employees, users, orders, errors, services, events, report}