import axios from "axios";
import moment from "moment";
import { PaymentCache } from "../../common/interfaces/payment-response.interface";
import { TransactionsResponse } from "../../common/interfaces/transaction.interface";
import { removeFromCache } from "../../common/utils/createPaymentCache";

export const wallet = {
  state: {
    wallet: {},
    transactions: [],
  },
  reducers: {
    setWallet(state: any, payload: any) {
      return {
        ...state,
        wallet: payload,
      };
    },
    setWalletTransactions(state: any, payload: any) {
      return {
        ...state,
        transactions: payload,
      };
    },
  },
  effects: (dispatch: any) => {
    return {
      fetchWallet: () => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/wallet`;
          axios
            .get(url)
            .then((response) => {
              resolve(response.data);
              dispatch.wallet.setWallet(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      fetchWalletTransactions: (payload: any) => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/wallet/transactions`;
          axios
            .post(url, payload)
            .then((response) => {
              const data: TransactionsResponse = response.data;
              resolve(data.result);
              dispatch.wallet.setWalletTransactions(data.result);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      createWalletTransactions: (payload: PaymentCache) => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/wallet/transactions/create`;
          axios
            .post(url, payload)
            .then((response) => {
              const data: TransactionsResponse = response.data;
              resolve(data.result);
              dispatch.errors.handleSuccess(`Your deposit of Ksh. ${payload.amount} has been received and added to your wallet.`)
              dispatch.wallet.setWalletTransactions(data.result);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      createPesapalTransaction: (payload: any) => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/wallet/payments/pesapal`;
          axios
            .post(url, payload)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      createFlutterwaveTransaction: (payload: any) => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/wallet/payments/flutterwave`;
          axios
            .post(url, payload)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      createAbsaTransaction: (payload: any) => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/wallet/payments/absa`;
          axios
            .post(url, payload)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      createMpesaTransaction: (payload: any) => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/wallet/payments/mpesa`;
          axios
            .post(url, payload)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      createPesaTransaction: (payload: any) => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/wallet/payments/pesapal`;
          axios
            .post(url, payload)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      createPaypalTransaction: (payload: any) => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/wallet/payments/paypal`;
          axios
            .post(url, payload)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      confirmPayment: (payload: PaymentCache) => {
        const created = moment(payload.created_at);
        const now = moment();

        if(now.diff(created, 'minutes') > 30) {
          console.log(now.diff(created, 'minutes'))
        }
        return new Promise((resolve, reject) => {
          let url = "";
          if (payload.type === "mpesa") {
            url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/wallet/confirmation/mpesa`;
          }
          if (payload.type === "pesapal") {
            url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/wallet/confirmation/pesapal`;
          }
          if (payload.type === "absa") {
            url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/wallet/confirmation/absa`;
          }
          if (payload.type === "paypal") {
            url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/wallet/confirmation/paypal`;
          }
          axios
            .post(url, payload)
            .then((response) => {
              const data: any = response.data;
              if (data.status !== "success") {
                setTimeout(() => {
                  dispatch.wallet.confirmPayment(payload);
                  resolve(response.data);
                }, 1000 * 60);
              } else {
                dispatch.wallet.createWalletTransactions(payload).then(() => {
                  removeFromCache(payload);
                  resolve(response.data);
                });
              }
            })
            .catch((error) => {
              setTimeout(() => {
                dispatch.wallet.confirmPayment(payload);
                reject(error);
              }, 1000 * 120);
            });
        });
      },
      login: (payload: any) => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/auth/finance/payments`;
          axios
            .post(url, payload)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      createSTKUrl: (payload: any) => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/wallet/payments/mpesa`;
          axios
            .post(url, payload)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
    };
  },
};
