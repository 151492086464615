import axios from "axios";
import { Employee } from "../../common/interfaces/employee.interface";
import { UserDetails } from "../../common/interfaces/userDetails.interface";

export const users = {
  state: {
    user: {},
  },
  reducers: {
    setUser(state: any, payload: any) {
      return {
        ...state,
        user: payload,
      };
    },
  },
  effects: (dispatch: any) => {
    return {
      getUser: () => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/user`;
          axios
            .get(url)
            .then((response) => {
              const user: UserDetails = response.data;
              console.log("User", user);
              if (user.is_superuser) {
                dispatch.users.setUser(user);
                resolve(user);
              } else {const employeeUrl = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/employees/single`;
              const body = {
                id: user.uuid,
              };
              axios
                .post(employeeUrl, body)
                .then((response) => {
                  const employee: Employee = response.data;
                  user.corporate_uuid = employee.corporate_uuid;
                  user.dateOfBirth = employee.date_of_birth;
                  user.empId = employee.empId;
                  user.full_name = employee.name;
                  user.gender = employee.gender;
                  user.phone = employee.telephone;
                  dispatch.users.setUser(user);
                  dispatch.employees.setCurrentEmployee(employee);
                  resolve(user);
                })
                .catch((error) => {
                  reject(error);
                });
              }
              
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      logout: () => {
        //   clear all cookies
        const cookies = document.cookie.split("; ");
        for (var c = 0; c < cookies.length; c++) {
          var d = window.location.hostname.split(".");
          while (d.length > 0) {
            var cookieBase =
              encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
              "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
              d.join(".") +
              " ;path=";
            var p = location.pathname.split("/");
            document.cookie = cookieBase + "/";
            while (p.length > 0) {
              document.cookie = cookieBase + p.join("/");
              p.pop();
            }
            d.shift();
          }
        }
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/logout`;
          axios
            .get(url)
            .then((response) => {
              dispatch.users.setUser({});
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      forgotPassword: (email: string) => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/auth/forgot-password`;
          axios
            .post(url, { email: email })
            .then((response) => {
              dispatch.errors.handleSuccess(
                "An email has been sent to your email address with a link to reset your password."
              );
              resolve(response.data);
            })
            .catch((error) => {
              dispatch.errors.handleError("Error sending password reset email");
              reject(error);
            });
        });
      },
      resetPassword: (payload: any) => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/auth/reset-password`;
          axios
            .post(url, payload)
            .then((response) => {
              dispatch.errors.handleSuccess(
                "Your Password has been reset successfully"
              );
              resolve(response.data);
            })
            .catch((error) => {
              dispatch.errors.handleError(
                "Error resetting password. Try again later"
              );
              reject(error);
            });
        });
      },
      refreshPatientToken: (payload: any) => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/auth/refresh-patient-token`;
          axios
            .post(url, payload)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              dispatch.users.logout();
              dispatch.errors.handleError(
                "error refetching token. Log in again"
              );
              reject(error);
            });
        });
      },
    };
  },
};
