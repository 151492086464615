import axios from "axios";
import { EventsResponse } from "../../common/interfaces/v2/events/event.interface"


export const events = {
    state: {
        events: [],
        organizer: []
    },
    reducers: {
        setEvents: (state: {events: EventsResponse}, payload: EventsResponse) => {
            return {
                ...state,
                events: payload
            }
        }
    },
    effects: (dispatch: any) => {
        return {
            fetchEvents: () => {
                return new Promise((resolve, reject) => {
                  const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/events`;
                  axios
                    .get(url)
                    .then((response) => {
                      console.log('events', response.data)
                      resolve(response.data);
                      dispatch.wallet.setEvents(response.data);
                    })
                    .catch((error) => {
                      reject(error);
                    });
                });
              },
            fetchEventsOrders: (payload: any) => {
                return new Promise((resolve, reject) => {
                  const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/events/orders`;
                  axios
                    .post(url, payload)
                    .then((response) => {
                      console.log('events', response.data)
                      resolve(response.data);
                      dispatch.wallet.setEvents(response.data);
                    })
                    .catch((error) => {
                      reject(error);
                    });
                });
              },
              fetchPendingEvents: () => {
                return new Promise((resolve, reject) => {
                  const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/events/pending`;
                  axios
                    .get(url)
                    .then((response) => {
                      resolve(response.data);
                      dispatch.wallet.setEvents(response.data);
                    })
                    .catch((error) => {
                      reject(error);
                    });
                });
              },
              fetchComplete: () => {
                return new Promise((resolve, reject) => {
                  const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/events/completed`;
                  axios
                    .get(url)
                    .then((response) => {
                      resolve(response.data);
                      dispatch.wallet.setEvents(response.data);
                    })
                    .catch((error) => {
                      reject(error);
                    });
                });
              },
              createEvent: (payload: any) => {
                return new Promise((resolve, reject) => {
                  const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/events/create`;
                  axios
                    .post(url, payload)
                    .then((response) => {
                      resolve(response.data);
                      dispatch.wallet.setEvents(response.data);
                    })
                    .catch((error) => {
                      reject(error);
                    });
                });
              },
              fetchSingle: (payload: any) => {
                return new Promise((resolve, reject) => {
                  const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/events/single`;
                  axios
                    .post(url, payload)
                    .then((response) => {
                      resolve(response.data);
                    })
                    .catch((error) => {
                      reject(error);
                    });
                });
              },
              createOrganizer: (payload: any) => {
                return new Promise((resolve, reject) => {
                  const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/events/create-organizer`;
                  axios
                    .post(url, payload)
                    .then((response) => {
                      resolve(response.data);
                    })
                    .catch((error) => {
                      reject(error);
                    });
                });
              },
        }
    }
}