import { createTheme } from "@mui/material/styles";
import { Colors } from "../colors/colors";

export const theme = createTheme({
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(),
  },
  palette: {
    primary: {
      main: "#692D91",
    },
  },
  components: {
    MuiListItemText: {
      styleOverrides: {
        root: {
          "&.MuiListItemText-root": {
            fontSize: "0.8rem",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "0.8rem",
          "&:hover": {
            backgroundImage: Colors.primaryGradient,
            color: Colors.white,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: "0.8rem",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: Colors.pageBG,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          marginBlock: "2rem",
          fontSize: "0.8rem",
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          border: "none",
          boxShadow: "none",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: "0.8rem"
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        filled: {
          backgroundImage: Colors.primaryGradient,
          color: Colors.white,
        }
      }
    }
  },
});
