export const Colors = {
    primary: '#692D91',
    primaryGradient: 'linear-gradient(90deg, #4847F6 0%, #8455DC 57%, #C167F8 100%)',
    secondary: '#00FFAB',
    light: '#1A1A1A',
    dark: '#000000',
    white: '#FFFFFF',
    lightBG: '#F0F0F0',
    error: '#e60505',
    verticalGradient: 'linear-gradient(180deg, #4847F6 0%, #8455DC 57%, #C167F8 100%)',
    pageBG: '#E4E4F0',
    borderColor: '#653C96'
}