import React, {useState, useEffect} from 'react'
import '../styles/globals.css'
import type { AppProps } from 'next/app'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from '../common/theme/theme'
import { CacheProvider, EmotionCache } from '@emotion/react';
import createEmotionCache from '../common/utils/createEmotionCache';
import { Provider } from 'react-redux'
import { store } from '../store';
import { useRouter } from 'next/router'
import NProgress from 'nprogress'
import NextNProgress from "nextjs-progressbar";
import {Colors} from "../common/colors/colors";

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

function MyApp(props: MyAppProps) {
  const [loading, setLoading] = useState(false)
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  const router = useRouter()


  useEffect(() => {
    console.log(process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID)
    const handleStart = (url: any) => {
      console.log(`Loading: ${url}`)
      NProgress.start()
      setLoading(true)
    }
    const handleStop = () => {
      setLoading(false)
      NProgress.done()
    }

    router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleStop)
    router.events.on('routeChangeError', handleStop)

  }, [router])
  return (

        <Provider store={store}>
          <CacheProvider value={emotionCache}>
            <ThemeProvider theme={theme}>
              <NextNProgress color={Colors.primaryGradient} />
              <Component {...pageProps} />
            </ThemeProvider>
          </CacheProvider>
        </Provider>



  )
}

export default MyApp
