import axios from "axios";
import { EmployeeListResponse } from "../../common/interfaces/employee.interface";
import { HrResponse } from "../../common/interfaces/hr.interface";

export const employees = {
  state: {
    employees: [],
    hrs: [],
    currentEmployee: {},
    dependantTypes: [],
    gendertypes: [],
  },
  reducers: {
    setEmployees(state: any, payload: any) {
      return {
        ...state,
        employees: payload,
      };
    },
    setHrs(state: any, payload: any) {
      return {
        ...state,
        hrs: payload,
      };
    },
    setCurrentEmployee(state: any, payload: any) {
      return {
        ...state,
        currentEmployee: payload,
      };
    },
    setDependantTypes(state: any, payload: any) {
      return {
        ...state,
        dependantTypes: payload,
      };
    },
    setGenderTypes(state: any, payload: any) {
      return {
        ...state,
        gendertypes: payload,
      };
    },
  },
  effects: (dispatch: any) => {
    return {
      fetchEmployees: (payload: any) => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/employees`;

          axios
            .get(url)
            .then((response) => {
              const data: EmployeeListResponse = response.data;
              resolve(data.result);
              dispatch.employees.setEmployees(data.result);
            })
            .catch((error) => {
              dispatch.errors.handleError("Failed to fetch employees");
              reject(error);
            });
        });
      },
      fetchEmployee: (payload: any) => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/employees/single`;
          const body = {
            id: payload.uuid,
          };
          axios
            .post(url, body)
            .then((response) => {
              const data: EmployeeListResponse = response.data;
              resolve(data);
              dispatch.employees.setEmployees(data.result);
            })
            .catch((error) => {
              dispatch.errors.handleError("Failed to fetch employees");
              reject(error);
            });
        });
      },

      fetchHRs: () => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/corporate/hr`;
          axios
            .get(url)
            .then((response) => {
              dispatch.employees.setHrs(response.data);
              resolve(response.data);
            })
            .catch((error) => {
              dispatch.errors.handleError("Failed to fetch HRs");
              reject(error);
            });
        });
      },
      fetchHRsByCorp: () => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/corporate/hr/corp`;
          axios
            .get(url)
            .then((response) => {
              const data: HrResponse = response.data;
              dispatch.employees.setHrs(data.result);
              resolve(data.result);
            })
            .catch((error) => {
              dispatch.errors.handleError("Failed to fetch HRs");
              reject(error);
            });
        });
      },
      addHR: (payload: any) => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/patients/create-hr`;
          axios
            .post(url, payload)
            .then((response) => {
              dispatch.employees.setHrs(response.data);
              dispatch.errors.handleSuccess("HR added successfully");
              resolve(response.data);
            })
            .catch((error) => {
              if (error.response) {
                dispatch.errors.handleError(
                  error.response.data["detail"]["msg"]
                );
              } else if (error.request) {
                dispatch.errors.handleError("Failed to connect to the server");
              } else {
                dispatch.errors.handleError("Failed to connect to the server");
              }
              reject(error);
            });
        });
      },
      addUserToCHM: (payload: any) => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/patients/create-to-chm`;
          axios
            .post(url, payload)
            .then((response) => {
              dispatch.employees.setHrs(response.data);
              dispatch.errors.handleSuccess("Added successfully");
              resolve(response.data);
            })
            .catch((error) => {
              if (error.response) {
                dispatch.errors.handleError(
                  error.response.data["detail"]["msg"]
                );
              } else if (error.request) {
                dispatch.errors.handleError("Failed to connect to the server");
              } else {
                dispatch.errors.handleError("Failed to connect to the server");
              }
              reject(error);
            });
        });
      },
      addSingleEmployee: (payload: any) => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/patients/create`;
          axios
            .post(url, payload)
            .then((response) => {
              // dispatch.errors.handleSuccess("Successfully added employee");
              resolve(response.data);
            })
            .catch((error) => {
              if (error.response) {
                dispatch.errors.handleError(
                  error.response.data["detail"]["msg"]
                );
              } else if (error.request) {
                dispatch.errors.handleError("Failed to connect to the server");
              } else {
                dispatch.errors.handleError("Failed to connect to the server");
              }
              reject(error);
            });
        });
      },
      addToConcierge: (payload: any) => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/employees/create`;
          axios
            .post(url, payload)
            .then((response) => {
              // dispatch.errors.handleSuccess("Successfully added employee");
              resolve(response.data);
            })
            .catch((error) => {
              if (error.response) {
                dispatch.errors.handleError(
                  error.response.data["detail"]["msg"]
                );
              } else if (error.request) {
                dispatch.errors.handleError("Failed to connect to the server");
              } else {
                dispatch.errors.handleError("Failed to connect to the server");
              }
              reject(error);
            });
        });
      },
      searchEmployee: (payload: any) => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/employees/search-user`;
          axios
            .post(url, payload)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              dispatch.errors.handleError("Failed to fetch details");
              reject(error);
            });
        });
      },
      addBulkEmployees: (payload: any) => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/employees/bulk-add`;
          axios
            .post(url, payload)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              if (error.response) {
                dispatch.errors.handleError(
                  error.response.data["detail"]["msg"]
                );
              } else if (error.request) {
                dispatch.errors.handleError("Failed to connect to the server");
              } else {
                dispatch.errors.handleError("Failed to connect to the server");
              }
              reject(error);
            });
        });
      },
      addCHMEmployee: (payload: any) => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/employees/add-chm`;
          axios
            .post(url, payload)
            .then((response) => {
              dispatch.errors.handleSuccess("Successfully added employee");
              resolve(response.data);
            })
            .catch((error) => {
              if (error.response) {
                dispatch.errors.handleError(
                  "Failed to add employee. Check data and try again"
                );
              } else if (error.request) {
                dispatch.errors.handleError("Failed to connect to the server");
              } else {
                dispatch.errors.handleError("Failed to connect to the server");
              }
              reject(error);
            });
        });
      },
      editEmployee: (payload: any) => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/employees/edit`;
          axios
            .post(url, payload)
            .then((response) => {
              dispatch.errors.handleSuccess("Successfully edited employee");
              resolve(response.data);
            })
            .catch((error) => {
              if (error.response) {
                dispatch.errors.handleError(
                  error.response.data["detail"]["msg"]
                );
              } else if (error.request) {
                dispatch.errors.handleError("Failed to connect to the server");
              } else {
                dispatch.errors.handleError("Failed to connect to the server");
              }
              reject(error);
            });
        });
      },
      addEmployeeLocation: (payload: any) => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/employees/patients/add-location`;
          axios
            .post(url, payload)
            .then((response) => {
              dispatch.errors.handleSuccess("Successfully added employee");
              resolve(response.data);
            })
            .catch((error) => {
              if (error.response) {
                dispatch.errors.handleError(
                  error.response.data["detail"]["msg"]
                );
              } else if (error.request) {
                dispatch.errors.handleError("Failed to connect to the server");
              } else {
                dispatch.errors.handleError("Failed to connect to the server");
              }
              reject(error);
            });
        });
      },
      getDependantTypes: () => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/patients/dependant-type`;
          axios
            .get(url)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              dispatch.errors.handleError("Failed to fetch details");
              reject(error);
            });
        });
      },
      getGenderTypes: () => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/patients/gender-type`;
          axios
            .get(url)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              dispatch.errors.handleError("Failed to fetch details");
              reject(error);
            });
        });
      },
      createDependant: (payload: any) => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/patients/add-dependant`;
          axios
            .post(url, payload)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              if (error.response) {
                dispatch.errors.handleError(
                  "failed to create dependant. Check data and try again"
                );
              } else if (error.request) {
                dispatch.errors.handleError("Failed to connect to the server");
              } else {
                dispatch.errors.handleError("Failed to connect to the server");
              }
              reject(error);
            });
        });
      },
      createDependantCHM: (payload: any) => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/patients/add-dep-chm`;
          axios
            .post(url, payload)
            .then((response) => {
              dispatch.errors.handleSuccess("Successfully added dependant");
              resolve(response.data);
            })
            .catch((error) => {
              if (error.response) {
                dispatch.errors.handleError(
                  "failed to create dependant. Check data and try again"
                );
              } else if (error.request) {
                dispatch.errors.handleError("Failed to connect to the server");
              } else {
                dispatch.errors.handleError("Failed to connect to the server");
              }
              reject(error);
            });
        });
      },
      getDependants: (payload: any) => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/employees/dependants`;
          axios
            .post(url, payload)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              if (error.response) {
                dispatch.errors.handleError(
                  "failed to fetch dependants. Check data and try again"
                );
              } else if (error.request) {
                dispatch.errors.handleError("Failed to connect to the server");
              } else {
                dispatch.errors.handleError("Failed to connect to the server");
              }
              reject(error);
            });
        });
      },
      updatePatient: (payload: any) => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/patients/update`;
          axios
            .post(url, payload)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              if (error.response) {
                dispatch.errors.handleError(
                  "failed to fetch dependants. Check data and try again"
                );
              } else if (error.request) {
                dispatch.errors.handleError("Failed to connect to the server");
              } else {
                dispatch.errors.handleError("Failed to connect to the server");
              }
              reject(error);
            });
        });
      },
      removeEmployee: (payload: any) => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/employees/remove`;
          axios
            .post(url, payload)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              if (error.response) {
                dispatch.errors.handleError(
                  "failed to fetch dependants. Check data and try again"
                );
              } else if (error.request) {
                dispatch.errors.handleError("Failed to connect to the server");
              } else {
                dispatch.errors.handleError("Failed to connect to the server");
              }
              reject(error);
            });
        });
      },
    };
  },
};
