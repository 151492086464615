import axios from "axios";
import {
  ConciergeCorporate,
  Corporate,
  CorporatesListResponse,
} from "../../common/interfaces/corporate.interface";
import { CreditGroup } from "../../common/interfaces/creditGroup.interface";

export const corporates = {
  state: {
    corporate: {},
    corporates: [],
    creditGroups: [],
  },
  reducers: {
    setCorporate(state: any, payload: any) {
      return {
        ...state,
        corporate: payload,
      };
    },
    setCorporates(state: any, payload: any) {
      return {
        ...state,
        corporates: payload,
      };
    },
    setCreditGroups(state: any, payload: any) {
      return {
        ...state,
        creditGroups: payload,
      };
    },
  },
  effects: (dispatch: any) => {
    return {
      fetchCorporate: () => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/corporate`;
          axios
            .get(url)
            .then((response) => {
              dispatch.corporates.setCorporate(response.data);
              resolve(response.data);
            })
            .catch((error) => {
              if (error.response) {
                dispatch.errors.handleError(
                  error.response.data["detail"]["msg"]
                );
              } else if (error.request) {
                dispatch.errors.handleError("Failed to connect to the server");
              } else {
                dispatch.errors.handleError("Failed to connect to the server");
              }
              reject(error);
            });
        });
      },
      getCorporates: () => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/corporate/all`;
          axios
            .get(url)
            .then((response) => {
              const data: CorporatesListResponse = response.data;
              dispatch.corporates.setCorporates(data.result);
              resolve(response.data);
            })
            .catch((error) => {
              if (error.response) {
                dispatch.errors.handleError(
                  error.response.data["detail"]["msg"]
                );
              } else if (error.request) {
                dispatch.errors.handleError("Failed to connect to the server");
              } else {
                dispatch.errors.handleError("Failed to connect to the server");
              }
              reject(error);
            });
        });
      },
      getCreditGroups: () => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/credit-groups`;
          axios
            .get(url)
            .then((response) => {
              const data: CreditGroup = response.data;
              dispatch.corporates.setCreditGroups(data);
              resolve(data);
            })
            .catch((error) => {
              if (error.response) {
                dispatch.errors.handleError(
                  error.response.data["detail"]["msg"]
                );
              } else if (error.request) {
                dispatch.errors.handleError("Failed to connect to the server");
              } else {
                dispatch.errors.handleError("Failed to connect to the server");
              }
              reject(error);
            });
        });
      },
      createCorporate: (corporate: Corporate) => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/corporate/all/create`;
          axios
            .post(url, corporate)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              if (error.response) {
                dispatch.errors.handleError(
                  error.response.data["detail"]["msg"]
                );
              } else if (error.request) {
                dispatch.errors.handleError("Failed to connect to the server");
              } else {
                dispatch.errors.handleError("Failed to connect to the server");
              }
              reject(error);
            });
        });
      },
      updateCorporate: (body: Corporate) => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/corporate/all/edit`;
          axios
            .post(url, body)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              if (error.response) {
                dispatch.errors.handleError(
                  error.response.data["detail"]["msg"]
                );
              } else if (error.request) {
                dispatch.errors.handleError("Failed to connect to the server");
              } else {
                dispatch.errors.handleError("Failed to connect to the server");
              }
              reject(error);
            });
        });
      },
      addJobGroup: (body: any) => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/corporate/all/create/job-group`;
          axios
            .post(url, body)
            .then((response) => {
                dispatch.errors.handleSuccess('Successfully added job group');
              resolve(response.data);
            })
            .catch((error) => {
              if (error.response) {
                dispatch.errors.handleError(
                  error.response.data["detail"]["msg"]
                );
              } else if (error.request) {
                dispatch.errors.handleError("Failed to connect to the server");
              } else {
                dispatch.errors.handleError("Failed to connect to the server");
              }
              reject(error);
            });
        });
      },
      editJobGroup: (body: any) => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/corporate/all/edit/job-group`;
          axios
            .post(url, body)
            .then((response) => {
                dispatch.errors.handleSuccess('Successfully edited job group');
              resolve(response.data);
            })
            .catch((error) => {
              if (error.response) {
                dispatch.errors.handleError(
                  error.response.data["detail"]["msg"]
                );
              } else if (error.request) {
                dispatch.errors.handleError("Failed to connect to the server");
              } else {
                dispatch.errors.handleError("Failed to connect to the server");
              }
              reject(error);
            });
        });
      },
      addBranch: (body: any) => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/corporate/all/create/branch`;
          axios
            .post(url, body)
            .then((response) => {
                dispatch.errors.handleSuccess('Successfully added branch');
              resolve(response.data);
            })
            .catch((error) => {
              if (error.response) {
                dispatch.errors.handleError(
                  error.response.data["detail"]["msg"]
                );
              } else if (error.request) {
                dispatch.errors.handleError("Failed to connect to the server");
              } else {
                dispatch.errors.handleError("Failed to connect to the server");
              }
              reject(error);
            });
        });
      },
      editBranch: (body: any) => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/corporate/all/edit/branch`;
          axios
            .post(url, body)
            .then((response) => {
                dispatch.errors.handleSuccess('Successfully edited branch');
              resolve(response.data);
            })
            .catch((error) => {
              if (error.response) {
                dispatch.errors.handleError(
                  error.response.data["detail"]["msg"]
                );
              } else if (error.request) {
                dispatch.errors.handleError("Failed to connect to the server");
              } else {
                dispatch.errors.handleError("Failed to connect to the server");
              }
              reject(error);
            });
        });
      }
    };
  },
};
