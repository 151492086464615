import ErrorMessageData from '../../common/interfaces/errorMessage.interface';

export const errors = {
  state: {
    errorPresent: false,
    successPresent: false,
    errorMessages: [] as ErrorMessageData[],
    successMessage: '',
  },
  reducers: {
    addErrorMessage: (state: { errorMessages: any; }, payload: any) => {
      const errors: ErrorMessageData[] = Object.assign([], state.errorMessages);
      errors.push(payload);
      return {
        ...state,
        errorPresent: true,
        errorMessages: errors,
      };
    },
    removeErrorMessage: (state: { errorMessages: any; }, payload: any) => {
      const errors: ErrorMessageData[] = Object.assign([], state.errorMessages);
      const newErrors = errors.filter(
        (error) => !(error.detail['msg'] === payload)
      );
      let errorPresent = newErrors.length > 0;
      return {
        ...state,
        errorPresent: errorPresent,
        errorMessages: newErrors,
      };
    },
    addSuccessMessage: (state: any, payload: any) => {
      return {
        ...state,
        successPresent: true,
        successMessage: payload,
      };
    },
    removeSuccessMessage: (state: any) => {
      return {
        ...state,
        successPresent: false,
        successMessage: '',
      };
    },
  },
  effects: (dispatch: any) => {
    return {
      handleError(message: string) {
        const errorMessage: ErrorMessageData = {
          isError: true,
          detail: {
            msg: message,
          },
        };
        dispatch.errors.addErrorMessage(errorMessage);
      },
      handleSuccess(message: string) {
        const successMessage: ErrorMessageData = {
          isError: false,
          detail: {
            msg: message,
          },
        };
        dispatch.errors.addErrorMessage(successMessage);
      },
    };
  },
};
