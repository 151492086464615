import axios from "axios";
import { AppointmentsRes } from "../../common/interfaces/appointment.interface";

export const orders = {
  state: {
    orders: [],
    appointments: [],
  },
  reducers: {
    setOrders(state: any, orders: any) {
      return {
        ...state,
        appointments: orders,
      };
    },
    setLegacyOrders(state: any, orders: any) {
      return {
        ...state,
        orders: orders,
      };
    },
  },
  effects: (dispatch: any) => {
    return {
      getOrders: (payload: any) => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/orders`;
          axios
            .post(url, payload)
            .then((response) => {
              const data = response.data as AppointmentsRes;
              dispatch.orders.setOrders(data.result);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      getOrdersByEmployee: (payload: any) => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/orders/employee`;
          axios
            .post(url, payload)
            .then((response) => {
              const data = response.data as any;
              dispatch.orders.setLegacyOrders(data.results);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      makeOrder: (payload: any) => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/orders/create`;
          axios
            .post(url, payload)
            .then((response) => {
              dispatch.errors.handleSuccess("Successfully Created Order");
              resolve(response.data);
            })
            .catch((error) => {
              dispatch.errors.handleError("Failed to create order");
              reject(error);
            });
        });
      },
      getTransportFee: (payload: any) => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/orders/transport`;
          axios
            .post(url, payload)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              dispatch.errors.handleError("Failed to create order");
              reject(error);
            });
        });
      },
      getLegacyOrders: (payload: any) => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/orders/orders`;
          axios
            .post(url, payload)
            .then((response) => {
              const data = response.data as AppointmentsRes;
              dispatch.orders.setLegacyOrders(data.result);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    };
  },
};
