import axios from "axios";
import {ReportsResponse, ReportTransactionResponse} from "../../common/interfaces/report.interface";

export const report = {
  state: {
    reports: [],
    reportTransactions: []
  },
  reducers: {
    setReports(state: any, payload: any) {
      return {
        ...state,
        reports: payload,
      };
    },
    setReportTransaction(state: any, payload: any) {
      return {
        ...state,
        reportTransactions: payload,
      };
    }
  },
  effects: (dispatch: any) => {
    return {
      fetchReports: (payload: any) => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/report`;
          axios
            .post(url, payload)
            .then((response) => {
              const data: ReportsResponse = response.data;
              resolve(data.result);
              dispatch.report.setReports(data.result);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      fetchReportTransactions: (payload: any) => {
        return new Promise((resolve, reject) => {
          const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/report/transaction`;
          axios
            .post(url, payload)
            .then((response) => {
              const data: ReportTransactionResponse = response.data;
              resolve(data.result);
              dispatch.report.setReportTransaction(data.result);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    };
  },
};


