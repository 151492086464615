import moment from "moment";
import { Constants } from "../config/Constants";
import { db } from "../db/db";
import { PaymentCache } from "../interfaces/payment-response.interface";

export const setPaymentCache = async (payload: PaymentCache) => {
  payload.created_at = moment.now();
  try {
    const id = await db.payments.add(payload);
    console.log(
      `Successfully added ${payload.type} transaction amount Ksh: ${payload.amount}, id: ${id}`
    );
    return;
  } catch (error) {
    console.log(
      `Failed to add ${payload.type} transaction amount Ksh: ${payload.amount}`,
      error
    );
    return;
  }
};

export const removeFromCache = async (payload: PaymentCache) => {
  try {
    const id = await db.payments.where('id').equals(payload.id).delete();
    console.log(
      `Successfully removed ${payload.type} transaction amount Ksh: ${payload.amount}, id: ${id}`
    );
    return;
  } catch (error) {
    console.log(
      `Failed to remove ${payload.type} transaction amount Ksh: ${payload.amount}`,
      error
    );
    return;
  }
};

export const clearStaleItemsFromCache = async () => {
    const time = new Date(Date.now() - 1000*60*30)
    try {
        await db.payments.where('created_at').below(time);
        return;
    } catch (error) {
        console.log(
            `Failed to remove $stale items from cache`,
            error
          );
          return;
    }
}

export const readFromPaymentsCache = async () => {
  try {
    const data = await db.payments.toArray();
    return data;
  } catch (error) {
    console.log(error);
    return [] as PaymentCache[]
  }
}
