import axios from "axios";
import { HrResponse } from "../../common/interfaces/hr.interface";

export const services = {
    state: {
        services: {},
        selectedService: {},
        packages: {},
        products: {},
        algoliaServices: {}
    },
    reducers: {
        setServices(state: any, payload: any) {
            return {...state, services: payload};
        },
        setPackages(state: any, payload: any) {
            return {...state, packages: payload};
        },
        setSelectedService(state: any, payload: any) {
            return {...state, selectedService: payload};
        },
        setProducts(state: any, payload: any) {
            return {...state, products: payload};
        },
        setAlgoliaServices(state: any, payload: any) {
            return {...state, algoliaServices: payload};
        }
    },
    effects: (dispatch: any) => {
        return {
            fetchCategories: () => {

                return new Promise((resolve, reject) => {
                    const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/services/categories`;
                    axios.get(url).then(response => {
                        resolve(response.data);
                    }).catch(error => {
                        if (error.response) {
                            dispatch.errors.handleError(
                                error.response.data["detail"]["msg"]
                            );
                        } else if (error.request) {
                            dispatch.errors.handleError(
                                "Failed to connect to the server"
                            );
                        } else {
                            dispatch.errors.handleError(
                                "Failed to connect to the server"
                            );
                        }
                        reject(error);
                    });
                })
            },
            unlinkService: (payload: any) => {
                return new Promise((resolve, reject) => {
                    const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/services/unlink`;
                    axios
                        .post(url, payload)
                        .then((response) => {
                            dispatch.errors.handleSuccess('successfully unlinked service');
                            resolve(response.data);
                        })
                        .catch((error) => {
                            if (error.response) {
                                dispatch.errors.handleError(
                                    error.response.data["detail"]["msg"]
                                );
                            } else if (error.request) {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            } else {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            }
                            reject(error);
                        });
                });
            },
            linkService: (payload: any) => {
                return new Promise((resolve, reject) => {
                    const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/services/link`;
                    axios
                        .post(url, payload)
                        .then((response) => {
                            dispatch.errors.handleSuccess('successfully linked service');
                            resolve(response.data);
                        })
                        .catch((error) => {
                            if (error.response) {
                                dispatch.errors.handleError(
                                    error.response.data["detail"]["msg"]
                                );
                            } else if (error.request) {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            } else {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            }
                            reject(error);
                        });
                });
            },
            linkDefaultService: (payload: any) => {
                return new Promise((resolve, reject) => {
                    const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/services/link/default`;
                    axios
                        .post(url, payload)
                        .then((response) => {
                            dispatch.errors.handleSuccess('successfully linked service');
                            resolve(response.data);
                        })
                        .catch((error) => {
                            if (error.response) {
                                dispatch.errors.handleError(
                                    error.response.data["detail"]["msg"]
                                );
                            } else if (error.request) {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            } else {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            }
                            reject(error);
                        });
                });
            },
            algoliaSearch: (payload: any) => {
                return new Promise((resolve, reject) => {
                    const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/services/algolia`;
                    axios
                        .post(url, payload)
                        .then((response) => {
                            // dispatch.services.setPackages({});
                            dispatch.services.setAlgoliaServices(response.data);
                            resolve(response.data);
                        })
                        .catch((error) => {
                            if (error.response) {
                                dispatch.errors.handleError(
                                    error.response.data["detail"]["msg"]
                                );
                            } else if (error.request) {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            } else {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            }
                            reject(error);
                        });
                });
            },
            algoliaCompare: (payload: any) => {
                return new Promise((resolve, reject) => {
                    const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/services/algolia/line-number`;
                    axios
                        .post(url, payload)
                        .then((response) => {
                            // dispatch.services.setPackages({});
                            dispatch.services.setAlgoliaServices(response.data);
                            resolve(response.data);
                        })
                        .catch((error) => {
                            if (error.response) {
                                dispatch.errors.handleError(
                                    error.response.data["detail"]["msg"]
                                );
                            } else if (error.request) {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            } else {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            }
                            reject(error);
                        });
                });
            },
            scSearch: (payload: any) => {
                return new Promise((resolve, reject) => {
                    const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/catalogue/search`;
                    axios
                        .post(url, payload)
                        .then((response) => {
                            dispatch.services.setPackages(response.data);
                            resolve(response.data);
                        })
                        .catch((error) => {
                            if (error.response) {
                                dispatch.errors.handleError(
                                    error.response.data["detail"]["msg"]
                                );
                            } else if (error.request) {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            } else {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            }
                            reject(error);
                        });
                });
            },
            filter: (payload: any) => {
                return new Promise((resolve, reject) => {
                    const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/catalogue/search/filter`;
                    axios
                        .post(url, payload)
                        .then((response) => {
                            dispatch.services.setPackages(response.data);
                            resolve(response.data);
                        })
                        .catch((error) => {
                            if (error.response) {
                                dispatch.errors.handleError(
                                    error.response.data["detail"]["msg"]
                                );
                            } else if (error.request) {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            } else {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            }
                            reject(error);
                        });
                });
            },
            fetchSingleService: (payload: any) => {
                return new Promise((resolve, reject) => {
                    const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/catalogue/product/single`;
                    axios
                        .post(url, payload)
                        .then((response) => {
                            dispatch.services.setPackages(response.data);
                            resolve(response.data);
                        })
                        .catch((error) => {
                            if (error.response) {
                                dispatch.errors.handleError(
                                    error.response.data["detail"]["msg"]
                                );
                            } else if (error.request) {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            } else {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            }
                            reject(error);
                        });
                });
            },
            fetchByLegacyId: (payload: any) => {
                return new Promise((resolve, reject) => {
                    const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/catalogue/search/legacy`;
                    axios
                        .post(url, payload)
                        .then((response) => {
                            dispatch.services.setPackages(response.data);
                            resolve(response.data);
                        })
                        .catch((error) => {
                            if (error.response) {
                                dispatch.errors.handleError(
                                    error.response.data["detail"]["msg"]
                                );
                            } else if (error.request) {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            } else {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            }
                            reject(error);
                        });
                });
            },
            expressSearch: (payload: any) => {
                return new Promise((resolve, reject) => {
                    const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/catalogue/express`;
                    axios
                        .post(url, payload)
                        .then((response) => {
                            dispatch.services.setPackages(response.data);
                            resolve(response.data);
                        })
                        .catch((error) => {
                            if (error.response) {
                                dispatch.errors.handleError(
                                    error.response.data["detail"]["msg"]
                                );
                            } else if (error.request) {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            } else {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            }
                            reject(error);
                        });
                });
            },
            packageDetails: (payload: any) => {
                return new Promise((resolve, reject) => {
                    const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/catalogue/package`;
                    axios
                        .post(url, payload)
                        .then((response) => {
                            // dispatch.services.setPackages(response.data);
                            resolve(response.data['returned_resultset']);
                        })
                        .catch((error) => {
                            if (error.response) {
                                dispatch.errors.handleError(
                                    // error.response.data["detail"]["msg"]
                                );
                            } else if (error.request) {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            } else {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            }
                            reject(error);
                        });
                });
            },
            fetchByLineNumber: (payload: any) => {
                return new Promise((resolve, reject) => {
                    const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/services/concierge/line-number`;
                    axios
                        .post(url, payload)
                        .then((response) => {
                            resolve(response.data);
                        })
                        .catch((error) => {
                            if (error.response) {
                                dispatch.errors.handleError(
                                    'Failed to fetch services'
                                );
                            } else if (error.request) {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            } else {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            }
                            reject(error);
                        });
                });
            },
            fetchByUUID: (payload: any) => {
                return new Promise((resolve, reject) => {
                    const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/services/concierge/uuid`;
                    axios
                        .post(url, payload)
                        .then((response) => {
                            resolve(response.data);
                        })
                        .catch((error) => {
                            if (error.response) {
                                dispatch.errors.handleError(
                                    error.response.data["detail"]["msg"]
                                );
                            } else if (error.request) {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            } else {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            }
                            reject(error);
                        });
                });
            },
            fetchCorporateServices: (payload: any) => {
                return new Promise((resolve, reject) => {
                    const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/services/fetch`;
                    axios
                        .post(url, payload)
                        .then((response) => {
                            const data: HrResponse = response.data;
                            resolve(data);
                        })
                        .catch((error) => {
                            if (error.response) {
                                dispatch.errors.handleError(
                                    error.response.data["detail"]["msg"]
                                );
                            } else if (error.request) {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            } else {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            }
                            reject(error);
                        });
                });
            },
            fetchDefaultServices: (payload: any) => {
                return new Promise((resolve, reject) => {
                    const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/services/default`;
                    axios
                        .post(url, payload)
                        .then((response) => {
                            const data: HrResponse = response.data;
                            resolve(data);
                        })
                        .catch((error) => {
                            if (error.response) {
                                dispatch.errors.handleError(
                                    error.response.data["detail"]["msg"]
                                );
                            } else if (error.request) {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            } else {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            }
                            reject(error);
                        });
                });
            },
            fetchCatalogueCategories: () => {
                return new Promise((resolve, reject) => {
                    const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/catalogue/categories`;
                    axios
                        .get(url)
                        .then((response) => {

                            resolve(response.data);
                        })
                        .catch((error) => {
                            if (error.response) {
                                dispatch.errors.handleError(
                                    error.response.data["detail"]["msg"]
                                );
                            } else if (error.request) {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            } else {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            }
                            reject(error);
                        });
                });
            },
            fetchExpressServices: () => {
                return new Promise((resolve, reject) => {
                    const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/catalogue/express`;
                    axios
                        .get(url)
                        .then((response) => {
                            dispatch.services.setServices({});
                            dispatch.services.setPackages(response.data);
                            resolve(response.data);
                        })
                        .catch((error) => {
                            if (error.response) {
                                dispatch.errors.handleError(
                                    error.response.data["detail"]["msg"]
                                );
                            } else if (error.request) {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            } else {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            }
                            reject(error);
                        });
                });
            },
            // service catalogue
            searchServices: (payload: any) => {
                return new Promise((resolve, reject) => {
                    const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/catalogue/search/service`;
                    axios
                        .post(url, payload)
                        .then((response) => {
                            dispatch.services.setServices(response.data);
                            resolve(response.data);
                        })
                        .catch((error) => {
                            if (error.response) {
                                dispatch.errors.handleError(
                                    error.response.data["detail"]["msg"]
                                );
                            } else if (error.request) {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            } else {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            }
                            reject(error);
                        });
                });
            },
            searchProducts: (payload: any) => {
                return new Promise((resolve, reject) => {
                    const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/catalogue/search/product`;
                    axios
                        .post(url, payload)
                        .then((response) => {
                            dispatch.services.setProducts(response.data);
                            resolve(response.data);
                        })
                        .catch((error) => {
                            if (error.response) {
                                dispatch.errors.handleError(
                                    error.response.data["detail"]["msg"]
                                );
                            } else if (error.request) {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            } else {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            }
                            reject(error);
                        });
                });
            },
            searchPackages: (payload: any) => {
                return new Promise((resolve, reject) => {
                    const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/catalogue/search/package`;
                    axios
                        .post(url, payload)
                        .then((response) => {
                            dispatch.services.setPackages(response.data);
                            resolve(response.data);
                        })
                        .catch((error) => {
                            if (error.response) {
                                dispatch.errors.handleError(
                                    error.response.data["detail"]["msg"]
                                );
                            } else if (error.request) {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            } else {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            }
                            reject(error);
                        });
                });
            },
            comparePackages: (payload: any) => {
                return new Promise((resolve, reject) => {
                    const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/catalogue/compare/package`;
                    axios
                        .post(url, payload)
                        .then((response) => {
                            // dispatch.services.setPackages(response.data);
                            resolve(response.data);
                        })
                        .catch((error) => {
                            if (error.response) {
                                dispatch.errors.handleError(
                                    error.response.data["detail"]["msg"]
                                );
                            } else if (error.request) {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            } else {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            }
                            reject(error);
                        });
                });
            },
            compareServices: (payload: any) => {
                return new Promise((resolve, reject) => {
                    const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/catalogue/compare/service`;
                    axios
                        .post(url, payload)
                        .then((response) => {
                            // dispatch.services.setPackages(response.data);
                            resolve(response.data);
                        })
                        .catch((error) => {
                            if (error.response) {
                                dispatch.errors.handleError(
                                    error.response.data["detail"]["msg"]
                                );
                            } else if (error.request) {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            } else {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            }
                            reject(error);
                        });
                });
            },
            serviceSearch: (payload: any) => {
                return new Promise((resolve, reject) => {
                    const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/services/search`;
                    axios
                        .post(url, payload)
                        .then((response) => {
                            // dispatch.services.setPackages(response.data);
                            resolve(response.data);
                        })
                        .catch((error) => {
                            if (error.response) {
                                dispatch.errors.handleError(
                                    error.response.data["detail"]["msg"]
                                );
                            } else if (error.request) {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            } else {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            }
                            reject(error);
                        });
                });
            },
            compareConcierge: (payload: any) => {
                return new Promise((resolve, reject) => {
                    const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/frontend/services/concierge/line-number`;
                    axios
                        .post(url, payload)
                        .then((response) => {
                            // dispatch.services.setPackages(response.data);
                            resolve(response.data);
                        })
                        .catch((error) => {
                            if (error.response) {
                                dispatch.errors.handleError(
                                    error.response.data["detail"]["msg"]
                                );
                            } else if (error.request) {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            } else {
                                dispatch.errors.handleError(
                                    "Failed to connect to the server"
                                );
                            }
                            reject(error);
                        });
                });
            },
        }
    }
}