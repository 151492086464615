import Dexie, { Table } from 'dexie';
import { PaymentCache } from '../interfaces/payment-response.interface';


export class PaymentsDexie extends Dexie {
  // 'friends' is added by dexie when declaring the stores()
  // We just tell the typing system this is the case
  payments!: Table<PaymentCache>; 

  constructor() {
    super('chmDatabase');
    this.version(2).stores({
      payments: '++uuid, type, id, account, amount, created_at' // Primary key and indexed props
    });
  }
}

export const db = new PaymentsDexie();